<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    @submit="onSubmit"
  >
    <div style="padding: 0 0 20px 0">
      <a-table
        :columns="table.columns"
        v-bind="table.tableprops.table"
        :data-source="modelRef.price_json"
        :pagination="false"
      >
        <template #prices="{ record }">
          <a-input
            type="text"
            placeholder="单价"
            :key="record.mintype"
            v-model:value="record.price"
          />
        </template>
      </a-table>
    </div>
    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit">保存</a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { onMounted, reactive } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import { tableProps } from '@/common/utils/common'
  export default {
    inject: ['formLayout'],
    emits: ['finish'],
    props: {
      data: {
        type: Object,
      },
    },
    setup(props, context) {
      const table = reactive({
        tableprops: tableProps,
        columns: [
          {
            title: '大类',
            dataIndex: 'title',
          },
          {
            title: '小类',
            dataIndex: 'label',
          },
          {
            title: '价格',
            dataIndex: 'label',
            slots: { customRender: 'prices' },
          },
        ],
      })
      const tableValue = [
        {
          id: 1,
          title: '效果图',
          label: '人视',
          price: undefined,
          mintype: '0-1',
        },
        {
          id: 2,
          title: '效果图',
          label: '鸟瞰',
          price: undefined,
          mintype: '0-2',
        },
        {
          id: 3,
          title: '效果图',
          label: '半鸟瞰',
          price: undefined,
          mintype: '0-3',
        },
        {
          id: 4,
          title: '效果图',
          label: '大鸟瞰',
          price: undefined,
          mintype: '0-4',
        },
        {
          id: 5,
          title: '效果图',
          label: '其他',
          price: undefined,
          mintype: '0-5',
        },
        {
          id: 6,
          title: '模型',
          label: '高层',
          price: undefined,
          mintype: '1-1',
        },
        {
          id: 7,
          title: '模型',
          label: '小高层',
          price: undefined,
          mintype: '1-2',
        },
        {
          id: 8,
          title: '模型',
          label: '洋房',
          price: undefined,
          mintype: '1-3',
        },
        {
          id: 7,
          title: '模型',
          label: '别墅',
          price: undefined,
          mintype: '1-4',
        },
        {
          id: 8,
          title: '模型',
          label: '售楼处/幼儿园',
          price: undefined,
          mintype: '1-5',
        },
        {
          id: 9,
          title: '模型',
          label: '商业',
          price: undefined,
          mintype: '1-6',
        },
        {
          id: 10,
          title: '模型',
          label: '其他',
          price: undefined,
          mintype: '1-7',
        },
        {
          id: 11,
          title: '多媒体/动画',
          label: '动画',
          price: undefined,
          mintype: '2-1',
        },
        {
          id: 12,
          title: '多媒体/动画',
          label: '多媒体',
          price: undefined,
          mintype: '2-2',
        },
        {
          id: 13,
          title: '多媒体/动画',
          label: '其他',
          price: undefined,
          mintype: '2-3',
        },
        {
          id: 14,
          title: '其他',
          label: '其他',
          price: undefined,
          mintype: '3-1',
        },
      ]
      const modelRef = reactive({
        //表单
        id: undefined,
        price_json: undefined,
      })
      onMounted(() => {
        const data = props.data.value
        modelRef.id = data.id
        modelRef.price_json = data.price_json
          ? JSON.parse(data.price_json)
          : tableValue
      })
      const onSubmit = () => {
        const data = JSON.parse(JSON.stringify(modelRef))
        data.price_json = JSON.stringify(data.price_json)
        requests({
          url: API.suppliers.UpdatePrice,
          data: data,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '设置成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      return {
        modelRef,
        onSubmit,
        table,
      }
    },
  }
</script>
