<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <a-form-item
      label="公司名称："
      name="company_name"
      v-bind="validateInfos['company_name']"
    >
      <a-input
        type="text"
        placeholder="名称"
        v-model:value="model.company_name"
      />
    </a-form-item>
    <a-form-item
      label="登录账号："
      name="username"
      v-bind="validateInfos['username']"
    >
      <a-input type="text" placeholder="账号" v-model:value="model.username" />
    </a-form-item>
    <a-form-item
      label="登录密码："
      name="password"
      v-bind="validateInfos['password']"
    >
      <a-input
        type="password"
        placeholder="密码"
        v-model:value="model.password"
      />
    </a-form-item>
    <a-form-item
      label="负责人："
      name="chief_name"
      v-bind="validateInfos['chief_name']"
    >
      <a-input
        type="text"
        placeholder="姓名"
        v-model:value="model.chief_name"
      />
    </a-form-item>
    <a-form-item label="手机号：" name="phone" v-bind="validateInfos['phone']">
      <a-input type="text" placeholder="手机号" v-model:value="model.phone" />
    </a-form-item>
    <a-form-item
      label="邮箱(Email)"
      name="email"
      v-bind="validateInfos['email']"
    >
      <a-input
        type="text"
        placeholder="邮箱(Email)"
        v-model:value="model.email"
      />
    </a-form-item>
    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit" @click="onSubmit">
        保存
      </a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  import { reactive } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  export default {
    inject: ['formLayout'],
    emits: ['finish'],
    setup(props, context) {
      const modelRef = reactive({
        //表单
        username: undefined, //登录账号
        password: undefined, //密码
        chief_name: undefined, //负责人名称
        phone: undefined, //手机号
        email: undefined, //邮箱
        company_name: undefined, //公司名称
      })
      const rulesRef = reactive({
        //校验
        username: [
          {
            required: true,
            type: 'string',
            message: '请设置登录账号！',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            type: 'string',
            message: '请设置密码！',
            trigger: 'change',
          },
        ],
        chief_name: [
          {
            required: true,
            type: 'string',
            message: '请设置公司负责人名称！',
            trigger: 'change',
          },
        ],
        company_name: [
          {
            required: true,
            type: 'string',
            message: '请设置服务商公司名称！',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            type: 'string',
            message: '请输入员工手机号！',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            type: 'string',
            message: '请输入员工邮箱！',
            trigger: 'change',
          },
        ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }

      const onSubmitForm = () => {
        requests({
          url: API.suppliers.Store,
          data: modelRef,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '添加成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const rformProps = {
        modelRef,
        rulesRef,
      }

      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
      }
    },
  }
</script>
